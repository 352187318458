import { PropsWithChildren, useContext } from 'react'
import { isBrowser } from '@/utils/env'
import { useGdprConsent } from '@/services/gdpr-consent'
import { useDefaultObservable } from '@/utils/event'
import { useRouter } from 'next/router'
import { LayoutContext } from '@/components/layout'

export type BottomButtonProps = PropsWithChildren<{ className?: string }>

export function VenueBottomButton(props: BottomButtonProps) {
  const { userAgreedToCookies$ } = useGdprConsent()
  const userAgreedToCookies = useDefaultObservable(userAgreedToCookies$)

  if (!userAgreedToCookies) {
    return null
  }

  return <BottomButton {...props} />
}

export function BottomButton(props: BottomButtonProps) {
  if (!isBrowser) {
    return null
  }

  return (
    <div
      className={`fixed left-0 bottom-0 w-full z-40 select-none ${props.className ?? ''}`}
      style={{ minHeight: '5rem' }}
    >
      <BottomButtonFrame>{props.children}</BottomButtonFrame>
    </div>
  )
}
export type BottomButtonFrameProps = PropsWithChildren<unknown>

export function BottomButtonFrame(props: BottomButtonFrameProps) {
  const router = useRouter()

  const layoutContext = useContext(LayoutContext)

  return (
    <div
      className={`mx-auto max-w-webAppMobileWidth ${
        (router.route === '/venue/[venueName]' || router.route === '/') &&
        !layoutContext.forceMobileLayout
          ? 'md:max-w-webAppMenuDesktopWidth'
          : ''
      } h-full px-5 pt-3 pb-5 bg-action dark:bg-actionDark rounded-t-lg`}
      style={{
        boxShadow: '0px -5px 4px rgba(0, 0, 0, 0.1)',
      }}
    >
      {props.children}
    </div>
  )
}
