import { keys, values } from 'shared/utils/array'
import { customDomains } from 'shared/custom-domains'
export const isBrowser = typeof window !== 'undefined'
export const isDevBrowser =
  isBrowser &&
  !['foodba.com', 'foodeon.com', 'foodeon-web.vercel.app', ...Object.keys(customDomains)].some(
    (d) => {
      return d === document.location.hostname
    },
  )
export const isDev = isDevBrowser || process.env.NODE_ENV === 'development'

export const stubStorage: Storage = {
  length: 0,
  clear() {
    return
  },
  getItem() {
    return null
  },
  key() {
    return null
  },
  removeItem() {
    return
  },
  setItem() {
    return
  },
}
declare global {
  interface Window {
    WINDOW_STORAGE: Record<string, string | undefined>
  }
}

if (isBrowser) {
  // eslint-disable-next-line immutable/no-mutation
  window.WINDOW_STORAGE = window.WINDOW_STORAGE ?? {}
}
function updateWindowStorageLength() {
  // eslint-disable-next-line immutable/no-mutation
  ;(windowStorage as any).length = keys(windowStorage).length
}
export const windowStorage: Storage = {
  length: 0,
  clear() {
    // eslint-disable-next-line immutable/no-mutation
    window.WINDOW_STORAGE = {}
    updateWindowStorageLength()
  },
  getItem(key) {
    return window.WINDOW_STORAGE[key] ?? null
  },
  key(index) {
    return (values(window.WINDOW_STORAGE)[index] as string | undefined) ?? null
  },
  removeItem(key) {
    // eslint-disable-next-line immutable/no-mutation
    delete window.WINDOW_STORAGE[key]
    updateWindowStorageLength()
  },
  setItem(key, value) {
    // eslint-disable-next-line immutable/no-mutation
    window.WINDOW_STORAGE[key] = value
    updateWindowStorageLength()
  },
}

export const localStorage = isBrowser ? window.localStorage : stubStorage

export const sessionStorage = isBrowser ? window.sessionStorage : stubStorage
