import { localStorage } from '@/utils/env'
import { useState, useEffect, useRef } from 'react'
import { checkSchemaVersionStatus } from './storage'
import { nanoid } from 'nanoid'
import { entries, keys } from 'shared/utils/array'
import fromEntries from 'object.fromentries'

export type Dict<T> = { [name: string]: T | undefined }
export type KeyEncoder<K> = {
  encode: (key: K) => string
  decode: (encodedKey: string) => K
}

export function usePersistantState<V>(
  storageKey: string,
  defaultValue: V,
  options: {
    storage?: Storage
    onStateChange?: (update: V) => void
    schemaVersion?: number
  } = {},
): [V, (v: V | ((v: V) => V)) => void] {
  const storage = getStorage<V | undefined>(storageKey, options.storage)
  if (options.schemaVersion) {
    const schemaVersionStatus = checkSchemaVersionStatus(
      storageKey,
      options.schemaVersion,
      options.storage,
    )
    if (schemaVersionStatus === 'outdated') {
      // wipe the whole storage when we introduce breaking changes by incrementing the schemaVersion
      storage.remove()
    }
  }
  const storageValue = storage.get()
  const calculatedDefaultValue = storageValue !== undefined ? storageValue : defaultValue
  if (calculatedDefaultValue !== storageValue) {
    storage.update(calculatedDefaultValue)
  }

  const [value, setValue] = useState<V>(calculatedDefaultValue)

  // change state gracefully when changing the storageKey
  useEffect(() => {
    if (value !== calculatedDefaultValue) {
      setValue(calculatedDefaultValue)
    }
  }, [storageKey])

  const previousStorageKeyRef = useRef<string>(storageKey)

  const set = (newValueOrFn: V | ((v: V) => V)) => {
    if (newValueOrFn instanceof Function) {
      setValue((oldValue) => {
        const newValue = newValueOrFn(oldValue)
        storage.update(newValue)
        options.onStateChange && options.onStateChange(newValue)
        return newValue
      })
      return
    }
    setValue(newValueOrFn)
    storage.update(newValueOrFn)
    options.onStateChange && options.onStateChange(newValueOrFn)
  }

  if (previousStorageKeyRef.current !== storageKey) {
    // eslint-disable-next-line immutable/no-mutation
    previousStorageKeyRef.current = storageKey
    return [calculatedDefaultValue, set]
  }

  return [value, set]
}

export function usePersistantMap<K, V>(
  storageKey: string,
  keyEncoder: KeyEncoder<K>,
  options: {
    storage?: Storage
    onStateChange?: (update: Dict<V>, newState: Dict<V>, newMap: ReadonlyMap<K, V>) => void
    schemaVersion?: number
  } = {},
): Map<K, V> {
  const persistantStorage = getDictStorage<V>(storageKey, options.storage)
  const schemaVersionStatus = checkSchemaVersionStatus(
    storageKey,
    options.schemaVersion,
    options.storage,
  )
  if (schemaVersionStatus === 'outdated') {
    // wipe the whole storage when we introduce breaking changes by incrementing the schemaVersion
    persistantStorage.update({})
  }

  const dictToMap = (dict: Dict<V>): Map<K, V> => {
    return new Map(
      Object.entries(dict)
        .filter(([, value]) => {
          return !!value
        })
        .map(([key, value]): [K, V] => {
          return [keyEncoder.decode(key), value!]
        }),
    )
  }

  const [store, setStore] = usePersistantState<Dict<V>>(
    storageKey,
    {},
    { storage: options.storage },
  )
  const updateStore = (update: Dict<V>) => {
    const updatedStore = {
      ...store,
      ...update,
    }
    setStore(updatedStore)

    const newMap = dictToMap(updatedStore)
    options.onStateChange && options.onStateChange(update, updatedStore, newMap)
  }

  const nativeMap = dictToMap(store)

  const storeMap: Map<K, V> = {
    size: nativeMap.size,
    get(key) {
      const encodedKey = keyEncoder.encode(key)
      return store[encodedKey]
    },
    has(key) {
      return !!storeMap.get(key)
    },
    entries: nativeMap.entries.bind(nativeMap),
    keys: nativeMap.keys.bind(nativeMap),
    values: nativeMap.values.bind(nativeMap),
    forEach: nativeMap.forEach.bind(nativeMap),
    [Symbol.iterator]: nativeMap[Symbol.iterator],
    [Symbol.toStringTag]: 'PersistantMap',
    set(key: K, value: V) {
      const encodedKey = keyEncoder.encode(key)
      updateStore({
        [encodedKey]: value,
      })
      return storeMap
    },

    delete(key: K) {
      const encodedKey = keyEncoder.encode(key)
      const existed = storeMap.has(key)
      updateStore({
        [encodedKey]: undefined,
      })

      return existed
    },

    clear() {
      const existingKeys = Object.keys(store)
      const updateToUndefined = Object.fromEntries(
        existingKeys.map((key) => {
          return [key, undefined]
        }),
      )
      updateStore(updateToUndefined)
    },
  }

  return storeMap
}

export type Indexed<R> = R & { __id: string }

export type PersistantCollection<R> = {
  get(id: string): Indexed<R> | undefined
  getAll(): Indexed<R>[]
  filter(query: Partial<R> | ((row: R) => boolean)): Indexed<R>[]
  insert(row: R): Indexed<R>
  set(id: string, newValues: Partial<R>): Indexed<R>
  update(fn: (row: R) => boolean, newValues: Partial<R>): Indexed<R>[]
  updateById(id: string, newValues: Partial<R>): boolean
  deleteById(id: string): boolean
  deleteWhen(query: Partial<R> | ((row: R) => boolean)): number
  clear(): number
}

export function usePersistantCollection<R>(
  storageKey: string,
  options: {
    storage?: Storage
    onStateChange?: (update: Dict<R>, newState: Dict<R>) => void
    schemaVersion?: number
  } = {},
): PersistantCollection<R> {
  const [persistantState, setPersistantState] = usePersistantState<Dict<R>>(
    storageKey,
    {},
    {
      storage: options.storage,
      schemaVersion: options.schemaVersion,
    },
  )

  // mutable by design
  // eslint-disable-next-line immutable/no-let
  let latestPersistantState = persistantState
  const updateState = (update: Dict<R>) => {
    if (keys(update).length === 0) {
      return
    }
    const newState = {
      ...latestPersistantState,
      ...update,
    }
    for (const id in newState) {
      if (newState[id] === undefined) {
        delete newState[id]
      }
    }

    latestPersistantState = newState
    setPersistantState(newState)
    options.onStateChange && options.onStateChange(update, newState)
  }

  const whereObj = (row: R, obj: Partial<R>) => {
    for (const field in row) {
      if (obj[field] && obj[field] !== row[field]) {
        return false
      }
    }

    return true
  }

  const collection: PersistantCollection<R> = {
    get(id) {
      const value = latestPersistantState[id]
      if (!value) {
        return undefined
      }
      return { ...value, __id: id }
    },
    getAll() {
      return collection.filter({})
    },
    filter(query) {
      return entries(latestPersistantState)
        .filter(([, row]) => {
          if (typeof query === 'function') {
            return query(row)
          }

          return whereObj(row, query)
        })
        .map(([id, row]) => {
          return {
            __id: id.toString(),
            ...row,
          }
        })
    },
    insert(row) {
      const newId = nanoid()
      updateState({
        [newId]: row,
      })
      return {
        __id: newId,
        ...row,
      }
    },
    set(id, newValues) {
      const currentState = latestPersistantState[id]
      if (!currentState) {
        throw new Error(`set: couldn't find id=${id} in collection=${storageKey}`)
      }
      const newState = {
        ...currentState,
        ...newValues,
      }

      updateState({
        [id]: newState,
      })

      return {
        __id: id,
        ...newState,
      }
    },
    update(fn, newValues) {
      const updates = entries(latestPersistantState)
        .filter(([, row]) => {
          return fn(row)
        })
        .map(([id, row]) => {
          return [id, { ...row, ...newValues, __id: id }] as [string, Indexed<R>]
        })

      updateState(fromEntries(updates))
      return updates.map(([, row]) => {
        return row
      })
    },
    updateById(id, newValues) {
      const currentState = latestPersistantState[id]
      if (!currentState) {
        return false
      }

      updateState({
        [id]: {
          ...currentState,
          ...newValues,
        },
      })

      return true
    },
    deleteById(id) {
      const currentState = latestPersistantState[id]
      if (!currentState) {
        return false
      }

      updateState({
        [id]: undefined,
      })

      return true
    },
    deleteWhen(query) {
      const idsToDelete = collection
        .filter((row) => {
          if (typeof query === 'function') {
            return query(row)
          }
          return whereObj(row, query)
        })
        .map((row) => {
          return row.__id
        })

      updateState(
        fromEntries(
          idsToDelete.map((id) => {
            return [id, undefined]
          }),
        ),
      )

      return idsToDelete.length
    },
    clear() {
      return collection.deleteWhen(() => {
        return true
      })
    },
  }

  return collection
}

export function getDictStorage<T>(key: string, storage?: Storage) {
  const valueStorage = getStorage<Dict<T>>(key, storage)

  return {
    get() {
      return valueStorage.get() || {}
    },
    update(updatedState: Dict<T>) {
      return valueStorage.update(updatedState)
    },
  }
}

export function getStorage<T>(key: string, storages?: Storage | Storage[]) {
  const storagesToUse = storages === undefined ? [localStorage] : [storages].flat()

  return {
    get(): T | undefined {
      for (const storage of storagesToUse) {
        const value = storage.getItem(key)
        if (value && value !== 'undefined') {
          return JSON.parse(value)
        }
      }
      return undefined
    },
    update(updatedState: T) {
      for (const storage of storagesToUse) {
        storage.setItem(key, JSON.stringify(updatedState))
      }
    },
    remove() {
      for (const storage of storagesToUse) {
        storage.removeItem(key)
      }
    },
  }
}
