import scrollTo from 'animated-scroll-to'
import {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  Ref,
  useEffect,
  RefObject,
  ChangeEvent,
  CSSProperties,
  ElementType,
  useContext,
} from 'react'
import { usePersistantState } from '@/utils/persistant-state-hooks'
import { useAppContext } from '@/pages/_app'
import { filterEvents, uiEvents, useSubject, useSubscription } from '@/utils/event'
import { FormContext, useFormContext } from '@/components/form'
import { CollapseContext } from './collapse'

// This seemed like the most frictionless way at the moment because we couldn't use generics
// since forwardRef screws them up
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
type ImplicitValue = any

export type InputProps = {
  autoFocus?: boolean
  type: string
  value: string
  id: string
  step?: string
  label?: string
  pattern?: string
  placeholder?: string
  height?: number
  style?: CSSProperties
  disabled?: boolean
  onFocus?: () => void
  onClick?: () => void
  onChange?: (
    value: string,
    implicitValue?: ImplicitValue,
  ) => [string, ImplicitValue?] | null | void
  validate?: (value: string, implicitValue?: ImplicitValue) => string[]
  validateOn?: 'change' | 'blur'
  required?: boolean
  schemaVersion?: number
  storage?: Storage
  helperText?: string
  autoComplete?: 'on' | 'off'
  InputComponent?: ElementType<InputComponentProps>
}

export type InputComponentProps = {
  id: string
  inputRef: RefObject<HTMLInputElement | HTMLTextAreaElement>
  value: RefObject<string>
  type: string
  placeholder?: string
  pattern?: string
  disabled: boolean
  required: boolean
  focused: boolean
  touched: boolean
  valid: boolean
  validationErrors: string[]
  label?: string
  helperText?: string | JSX.Element
  rows?: number
  autoComplete?: 'on' | 'off'
  actions: {
    change: (event: { target: { value: string } }) => void
    focus: () => void
    blur: () => void
    touch: () => { isValid: boolean }
  }
  autoFocus?: boolean
  step?: number
}

export const LegacyInput = forwardRef(InputWithRef)

export type InputRef = {
  value: string
  disabled: boolean
  implicitValue: ImplicitValue
  setValue(newValue: string, implicitValue?: ImplicitValue): void
  isValid: () => boolean
  touch: () => { isValid: boolean }
  element: HTMLInputElement | HTMLTextAreaElement
}

function InputWithRef(props: InputProps, ref: Ref<InputRef>) {
  const { t } = useAppContext()
  const collapseContext = useContext(CollapseContext)
  const formContext = useContext(FormContext)
  const [isTouched, setTouched] = useState(false)
  const [isFocused, setFocused] = useState(false)
  const [validationErrors, setValidationErrors] = useState<string[]>([])

  const stateChanges$ = useSubject<string>()
  const [value, setValue] = usePersistantState(makeInputStorageKey(props.id), props.value, {
    storage: props.storage,
    schemaVersion: props.schemaVersion,
    onStateChange: (newValue) => {
      stateChanges$.next(newValue)
    },
  })
  const [implicitValue, setImplicitValue] = usePersistantState<ImplicitValue>(
    `${makeInputStorageKey(props.id)}/implicit`,
    undefined,
    { storage: props.storage, schemaVersion: props.schemaVersion },
  )

  const validateInput = (inputValue: string, implicitValue?: ImplicitValue) => {
    const validationErrors = props.validate ? props.validate(inputValue, implicitValue) : []
    setValidationErrors(validationErrors)
    return validationErrors
  }

  useEffect(() => {
    if (value.length > 0) {
      validateInput(value, implicitValue)
    }
  }, [])

  const setValueAndValidate = (inputValue: string, implicitValue?: ImplicitValue) => {
    validateInput(inputValue, implicitValue)
    setValue(inputValue)
    setImplicitValue(implicitValue)
  }

  const inputIsInvalid =
    (props.required && isTouched && value.trim() === '') || validationErrors.length > 0

  const colors = {
    label: inputIsInvalid
      ? 'text-negative dark:text-negativeDark'
      : isFocused
      ? 'text-primary dark:text-primaryDark'
      : 'text-opaque dark:text-opaqueDark',
    border: inputIsInvalid
      ? 'border-negative'
      : isFocused
      ? 'border-primary dark:border-opaqueLight'
      : 'border-opaque',
    background: inputIsInvalid
      ? 'bg-backNegativeLight dark:bg-backOpaqueDark'
      : 'bg-back dark:bg-backOpaqueDark',
  }

  const inputRef = useRef<HTMLInputElement>(null)
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  // we need this specifically for the case of the page getting loaded from the server (e.g. page refresh);
  // in case of pure client navigation, just passing autoFocus to the input itself is enough
  useSubscription(uiEvents.pipe(filterEvents(['AppLoaded'])), () => {
    if (props.autoFocus) {
      inputRef.current?.focus()
      textareaRef.current?.focus()
    }
  })

  const isInputValid = () => {
    const inputValue = inputRef.current?.value || textareaRef.current?.value || ''
    const validationErrors = validateInput(inputValue, implicitValue)
    return (!props.required || value.trim().length > 0) && validationErrors.length === 0
  }

  function touch() {
    setTouched(true)
    const isValid = isInputValid()

    if (!isValid && collapseContext) {
      uiEvents.next({
        type: 'CollapseExpand',
        id: collapseContext.id,
      })
    }

    return {
      isValid,
    }
  }

  useEffect(() => {
    if (formContext && !props.InputComponent) {
      formContext.registerFormElement(props.id, {
        ref: inputRef,
        touch,
      })
    }
  }, [])

  useImperativeHandle(ref, () => {
    return {
      value,
      disabled: props.disabled ?? false,
      implicitValue,
      setValue: setValueAndValidate,
      touch,
      isValid: isInputValid,
      element: inputRef.current || textareaRef.current!,
    }
  })

  const className = `w-full text-lg leading-tight opacity-100 p-3 border ${
    props.disabled ? 'text-opaque dark:text-opaqueDark' : 'text-primary dark:text-primaryDark'
  } ${colors.border} ${colors.background} rounded focus:outline-none`

  const validateOn = props.validateOn ?? 'change'

  const onChange = (
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
    realInputValue?: string,
  ) => {
    event.persist()
    setTouched(true)
    const inputValue = realInputValue || inputRef.current?.value || textareaRef.current?.value || ''

    const valueFromCallback = props.onChange ? props.onChange(inputValue, implicitValue) : undefined

    const newValue = valueFromCallback || [inputValue, undefined]
    const [newInputValue, newImplicitValue] = newValue

    const { selectionStart, selectionEnd } = event.target

    if (validateOn === 'change') {
      validateInput(newInputValue, newImplicitValue)
    } else {
      setValidationErrors([])
    }
    setValue(newInputValue)
    setImplicitValue(newImplicitValue)

    // preserving the cursor in case we filtered out the newly-typed character
    // by default, React would move it to the end
    window.requestAnimationFrame(() => {
      if (newInputValue === inputValue) {
        return
      }

      const lengthDiff = newInputValue.length - inputValue.length
      // eslint-disable-next-line immutable/no-mutation
      event.target.selectionStart = selectionStart! + lengthDiff
      // eslint-disable-next-line immutable/no-mutation
      event.target.selectionEnd = selectionEnd! + lengthDiff
    })
  }

  const onFocus = () => {
    setFocused(true)
    props.onFocus && props.onFocus()
  }

  const onBlur = () => {
    setFocused(false)
    setTouched(true)
    const inputValue = inputRef.current?.value || textareaRef.current?.value || ''
    validateInput(inputValue, implicitValue)
  }

  useEffect(() => {
    if (props.type === 'number') {
      inputRef.current?.addEventListener('mousewheel', (e) => {
        return e.preventDefault()
      })
    }
  }, [])

  const helperText = (() => {
    if (validationErrors.length > 0) {
      return validationErrors.join(', ')
    }

    if (props.helperText && (!props.required || !isTouched || value !== '')) {
      return props.helperText
    }

    if (props.required) {
      return t('common:required')
    }

    return undefined
  })()

  const helperTextElement = <div className={`px-3 text-sm ${colors.label}`}>{helperText}</div>

  const autoComplete = props.autoComplete || 'off'

  return (
    <div className="w-full">
      <div
        className={`w-full ${colors.label}`}
        onClick={() => {
          return props.onClick && props.onClick()
        }}
      >
        {(() => {
          if (props.InputComponent) {
            return (
              <props.InputComponent
                id={props.id}
                inputRef={inputRef}
                type={props.type}
                label={props.label}
                pattern={props.pattern}
                placeholder={props.placeholder || ''}
                className={className}
                value={{ current: value }}
                disabled={!!props.disabled}
                required={!!props.required}
                focused={isFocused}
                touched={isTouched}
                valid={!inputIsInvalid}
                validationErrors={validationErrors}
                helperText={helperText}
                rows={props.height}
                autoComplete={autoComplete}
                actions={{
                  change: onChange as (event: { target: { value: string } }) => void,
                  focus: onFocus,
                  blur: onBlur,
                  touch: () => {
                    return { isValid: !inputIsInvalid }
                  },
                }}
              />
            )
          }
          if (props.height) {
            return (
              <label>
                {props.label ? <span>{props.label}</span> : null}
                <div>
                  <textarea
                    autoFocus={props.autoFocus}
                    ref={textareaRef}
                    rows={props.height}
                    className={`block ${className}`}
                    value={value}
                    placeholder={props.placeholder || ''}
                    disabled={!!props.disabled}
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    autoComplete={autoComplete}
                    data-input-id={props.id}
                  ></textarea>
                </div>
                {helperTextElement}
              </label>
            )
          }

          return (
            <label>
              {props.label ? <span>{props.label}</span> : null}
              <div>
                <input
                  autoFocus={props.autoFocus}
                  ref={inputRef}
                  type={props.type}
                  step={props.step ?? '0.000001'} // allow any floating numbers
                  pattern={props.pattern}
                  placeholder={props.placeholder || ''}
                  className={className}
                  value={value}
                  disabled={!!props.disabled}
                  style={{
                    pointerEvents: props.disabled ? 'none' : 'initial',
                    ...(props.style ?? {}),
                  }}
                  onChange={onChange}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  autoComplete={autoComplete}
                  data-input-id={props.id}
                />
              </div>
              {helperTextElement}
            </label>
          )
        })()}
      </div>
      <style jsx>{`
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          /* display: none; <- Crashes Chrome on hover */
          -webkit-appearance: none;
          margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
        }

        input[type='number'] {
          -moz-appearance: textfield; /* Firefox */
        }
      `}</style>
    </div>
  )
}

export type SelectProps<V extends string = string> = {
  id: string
  label: string
  options: {
    id: V
    text: string
  }[]
  initialOptionId?: V
  onChange?: (id: V) => void
  required?: boolean
  disabled?: boolean
  storage?: Storage
}

export type SelectRef<V extends string = string> = {
  value: V
  isValid: () => boolean
  touch: () => { isValid: boolean }
  element: HTMLSelectElement
}

export const Select = forwardRef(SelectWithRef)

function SelectWithRef<V extends string = string>(props: SelectProps<V>, ref: Ref<SelectRef<V>>) {
  const { t } = useAppContext()

  const [isTouched, setTouched] = useState(false)
  const [isFocused, setFocused] = useState(false)

  const emptyOption = { id: '__EMPTY__' as V, text: '' }

  const initialOptionFound = props.options.some((option) => {
    return option.id === props.initialOptionId
  })
  const options = [
    ...(props.required && (props.initialOptionId === undefined || !initialOptionFound)
      ? [emptyOption]
      : []),
    ...props.options,
  ]

  const initialOptionId = initialOptionFound ? props.initialOptionId! : options[0]?.id || ('' as V)

  const [value, setValue] = usePersistantState<V>(`select/${props.id}`, initialOptionId, {
    storage: props.storage,
  })

  const isValid = () => {
    if (props.required && value === emptyOption.id) {
      return false
    }

    return true
  }

  const highlightRequired = isTouched && !isValid()

  const colors = {
    pure: highlightRequired
      ? 'stroke-negative'
      : isFocused
      ? 'stroke-primary dark:stroke-opaqueLight'
      : 'stroke-opaque',
    label: highlightRequired
      ? 'text-negative dark:text-negativeDark'
      : isFocused
      ? 'text-primary dark:text-primaryDark'
      : 'text-opaque dark:text-opaqueDark',
    border: highlightRequired
      ? 'border-negative'
      : isFocused
      ? 'border-primary dark:border-opaqueLight'
      : 'border-opaque',
    background: highlightRequired
      ? 'bg-backNegativeLight dark:bg-backOpaqueDark'
      : 'bg-back dark:bg-backOpaqueDark',
  }

  const selectRef = useRef<HTMLSelectElement>(null)

  useImperativeHandle(ref, () => {
    return {
      value,
      isValid,
      touch: () => {
        setTouched(true)
        return { isValid: isValid() }
      },
      element: selectRef.current!,
    }
  })

  const className = `w-full p-3 text-lg border text-primary dark:text-primaryDark ${colors.border} ${colors.background} rounded focus:outline-none`
  const onChange = () => {
    setTouched(true)
    const newValue = selectRef.current!.value as V
    setValue(newValue)
    props.onChange && props.onChange(newValue)
  }

  const onFocus = () => {
    setFocused(true)
  }

  const onBlur = () => {
    setFocused(false)
    setTouched(true)
  }

  const arrowSvg = (
    <svg
      fill="none"
      width="12"
      height="8"
      viewBox="0 0 12 8"
      className={colors.pure}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 1.5L6 6.5L11 1.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )

  return (
    <div className={`w-full ${colors.label}`}>
      <label>
        <span>{props.label}</span>
        <div className="relative">
          <div
            className="absolute select-none pointer-events-none"
            style={{
              top: '42%',
              right: `${3 / 4}rem`,
            }}
          >
            {arrowSvg}
          </div>
          <select
            ref={selectRef}
            style={{
              height: `${53 / 16}rem`,
              WebkitAppearance: 'none',
              MozAppearance: 'none',
            }}
            disabled={!!props.disabled}
            className={className}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            value={value}
          >
            {options.map((option) => {
              return (
                <option value={option.id} key={option.id}>
                  {option.text}
                </option>
              )
            })}
          </select>
        </div>
        {props.required ? (
          <div className={`px-3 text-sm ${colors.label}`}>{t('common:required')}</div>
        ) : null}
      </label>
      <style jsx>{`
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          /* display: none; <- Crashes Chrome on hover */
          -webkit-appearance: none;
          margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
        }

        input[type='number'] {
          -moz-appearance: textfield; /* Firefox */
        }
      `}</style>
    </div>
  )
}

export const InputFile = forwardRef(InputFileWithRef)

export type InputFileRef = {
  element: HTMLInputElement
}

export type InputFileProps = {
  label: string
  onChange?: () => void
}

export function InputFileWithRef(props: InputFileProps, ref: Ref<InputFileRef>) {
  const { t } = useAppContext()
  const fileRef = useRef<HTMLInputElement>(null)
  const [fileName, setFileName] = useState<string | null>(null)

  useImperativeHandle(ref, () => {
    return {
      element: fileRef.current!,
    }
  })

  return (
    <div
      onClick={() => {
        fileRef.current?.click()
      }}
    >
      <input
        className="hidden"
        ref={fileRef}
        type="file"
        onChange={() => {
          setFileName(fileRef.current?.files?.[0]?.name || null)
          props.onChange && props.onChange()
        }}
      />
      <div className="text-opaque dark:text-opaqueDark">{props.label}</div>
      <div className="flex">
        <button
          type="button"
          className="flex justify-center items-center p-3 text-lg leading-tight border text-primary dark:text-primaryDark border-opaque bg-back dark:bg-backDark rounded focus:outline-none"
        >
          {t('common:choose')}
        </button>

        <div className="ml-3 flex flex-grow items-center p-3 text-lg leading-tight border-b text-primary dark:text-primaryDark border-opaque overflow-hidden">
          {fileName ?? t('input:noFileChoosen')}
        </div>
      </div>
    </div>
  )
}

export function touchInputs(refs: RefObject<InputRef | SelectRef>[]) {
  const nonNullRefs = refs.filter((ref) => {
    return ref.current
  })

  const isValid = nonNullRefs
    .map((ref) => {
      return ref.current!.touch().isValid
    })
    .every((x) => {
      return x === true
    })
  const firstInvalidElement = nonNullRefs
    .map((r) => {
      return r.current!
    })
    .find((ref) => {
      return !ref.isValid()
    })?.element

  if (firstInvalidElement) {
    setTimeout(() => {
      scrollTo(firstInvalidElement, {
        verticalOffset: -100,
      })
    }, 1)
  }

  return {
    isValid,
  }
}

export function makeInputStorageKey(id: string) {
  return `input/${id}`
}

export function AppInput(
  props: InputComponentProps & {
    style?: CSSProperties
  },
) {
  useFormContext({ props })
  const inputIsInvalid = !props.valid

  const colors = {
    label: inputIsInvalid
      ? 'text-negative dark:text-negativeDark'
      : props.focused
      ? 'text-primary dark:text-primaryDark'
      : 'text-opaque dark:text-opaqueDark',
    border: inputIsInvalid ? 'border-negative' : props.focused ? 'border-primary' : 'border-opaque',
    background: inputIsInvalid ? 'bg-backNegativeLight' : 'bg-inherit',
  }

  const className = `w-full p-3 text-lg leading-tight border opacity-100 ${
    props.disabled ? 'text-opaque dark:text-opaqueDark' : 'text-primary dark:text-primaryDark'
  } ${colors.border} ${colors.background} rounded focus:outline-none`

  const helperTextElement = <div className={`px-3 text-sm ${colors.label}`}>{props.helperText}</div>

  if (props.rows) {
    return (
      <label>
        {props.label ? <span>{props.label}</span> : null}
        <div>
          <textarea
            autoFocus={props.autoFocus}
            ref={props.inputRef as RefObject<HTMLTextAreaElement>}
            rows={props.rows}
            className={`block ${className}`}
            value={props.value.current ?? undefined}
            placeholder={props.placeholder || ''}
            disabled={!!props.disabled}
            onChange={props.actions.change}
            onFocus={props.actions.focus}
            onBlur={props.actions.blur}
            autoComplete={props.autoComplete}
            data-input-id={props.id}
          ></textarea>
        </div>
        {helperTextElement}
      </label>
    )
  }

  return (
    <label>
      {props.label ? <span>{props.label}</span> : null}
      <div>
        <input
          autoFocus={props.autoFocus}
          ref={props.inputRef as RefObject<HTMLInputElement>}
          type={props.type}
          step={props.step ?? '0.000001'} // allow any floating numbers
          pattern={props.pattern}
          placeholder={props.placeholder || ''}
          value={props.value.current ?? undefined}
          disabled={!!props.disabled}
          className={className}
          style={{
            pointerEvents: props.disabled ? 'none' : 'initial',
            ...(props.style ?? {}),
          }}
          onChange={props.actions.change}
          onFocus={props.actions.focus}
          onBlur={props.actions.blur}
          autoComplete={props.autoComplete}
          data-input-id={props.id}
        />
      </div>
      {helperTextElement}
    </label>
  )
}
